/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import cep from 'cep-promise';
import { FormEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateMask as updateMaskCPF } from '../../../../../helpers/masks/cpf';
import { updateMask as updateMaskDate } from '../../../../../helpers/masks/generalDate';
import { updateMask as updateMaskPhone } from '../../../../../helpers/masks/mobilePhone';
import { AuthWrapperContainer } from './ui';
// import { SidebarProps } from 'src/components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLoading,
  useLoading,
} from '../../../../../redux/loading/loadingSlice';
import {
  FormInputNameLogin,
  FormInputNameRegister,
  ShouldShowModal,
  ShouldShowModalProps,
} from '../../types';
// import { setCart, useCart } from 'src/redux/cart/cartSlice';
import { Auth } from 'src/model/Auth';
import ChangePassword from 'src/model/ChangePassword';
import ClientNotification from 'src/model/ClientNotification';
import RecoverEmail from 'src/model/RecoverEmail';
import RecoverPassword from 'src/model/RecoverPassword';
import {
  removeItem,
  setItem,
} from '../../../../../helpers/common/localStorage';
import { updateMask as updateMaskCEP } from '../../../../../helpers/masks/cep';
import validators from '../../../../../helpers/validators';
import { useDialog } from '../../../../../hooks/useDialog';
import useForm from '../../../../../hooks/useForm';
import { api } from '../../../../../services/api';
import { REACT_APP_AUTH, REACT_APP_USER } from '../../../../../utils/config';

interface AuthWrapperProps {
  children: React.ReactNode;
  isHomepage: boolean;
}

export const AuthWrapper = ({ children, isHomepage }: AuthWrapperProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notifications, setNotifications] = useState<ClientNotification[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [documentClient, setDocumentClient] = useState('');

  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  //   const { onShowSidebar } = useSidebar();

  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
    ShouldShowModal.LOGIN
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableLoginPassword, setEnableLoginPassword] =
    useState<boolean>(false);
  const [shouldShowPasswordToText, setShouldShowPasswordToText] =
    useState(false);
  const [shouldShowPasswordToText2, setShouldShowPasswordToText2] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pwd, setPwd] = useState(undefined as unknown as string);
  const [emailRecoverPassword, setEmailRecoverPassword] = useState(
    undefined as unknown as string
  );
  const [token, setToken] = useState(undefined as unknown as string);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nameRecoverPassword, setNameRecoverPassword] = useState(
    undefined as unknown as string
  );
  //   const [needCheckEmailCode, setNeedCheckEmailCode] = useState<boolean>(false);

  const { loading } = useSelector(useLoading);

  const location = useLocation();
  const codeRecieve = new URLSearchParams(location.search).get('code');
  if (codeRecieve && codeRecieve.trim().length > 0) {
    if (!token) {
      setToken(codeRecieve);
    }
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnGoTo = (href: string): void => {
    history.replace(href);
  };

  const handleOnToggle = (): void => {
    onToggle();
    // if (shouldShowModal == ShouldShowModal.TERMS) {
    //   handleOnSignUp();
    //   setTimeout(() => {
    //     const element = document.getElementById('termsAndConditions');
    //     if (element) {
    //       element.scrollIntoView();
    //     }
    //   }, 300);
    // }
  };

  const {
    formData: formDataLogin,
    formErrors: formErrorsLogin,
    setErrors: setErrorsLogin,
    onChangeFormInput: onChangeFormInputLogin,
    isFormValid: isFormValidLogin,
    resetForm: resetFormLogin,
  } = useForm({
    initialData: {
      email: '',
      password: '',
    },
    validators: {
      email: [validators.required],
      password: [
        validators.required,
        /*
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
        */
      ],
    },
  });

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeFormInput: onChangeFormInputRegister,
  } = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      phone: '',
      date: '',
      motherName: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      addressnumber: '',
      password: '',
      confirmPassword: '',
      terms: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      date: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      motherName: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      terms: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      date: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });

  const {
    formData: formDataForgotPassword,
    formErrors: formErrorsForgotPassword,
    setErrors: setErrorsForgotPassword,
    onChangeFormInput: onChangeFormInputForgotPassword,
    isFormValid: isFormValidForgotPassword,
    resetForm: resetFormForgotPassword,
  } = useForm({
    initialData: {
      email: '',
    },
    validators: {
      email: [validators.required],
    },
  });

  const {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  } = useForm({
    initialData: {
      password: '',
      confirmPassword: '',
    },
    validators: {
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
    },
  });

  const {
    formData: formDataRegisterConfirm,
    formErrors: formErrorsRegisterConfirm,
    onChangeFormInput: onChangeFormInputRegisterConfirm,
  } = useForm({
    initialData: {
      code: '',
    },
    validators: {
      code: [
        validators.required,
        validators.isNumeric,
        validators.minLength(6),
      ],
    },
  });

  const {
    formData: formDataChangeClientData,
    formErrors: formErrorsChangeClientData,
    onChangeFormInput: onChangeFormInputChangeClientData,
  } = useForm({
    initialData: {
      name: '',
      email: '',
      phone: '',
      date: '',
      motherName: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      date: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      motherName: [validators.required],
    },
    formatters: {
      phone: updateMaskPhone,
      date: updateMaskDate,
    },
  });

  const handleOnSignIn = () => {
    onSetVisible(false);
    resetFormLogin();
    showModal({
      value: ShouldShowModal.LOGIN,
      title: '',
    });
  };

  const handleOnLogout = () => {
    onSetVisible(false);
    showModal({
      value: ShouldShowModal.LOGOUT,
      title: '',
    });
  };

  //   const handleOnSignUp = () => {
  //     onSetVisible(false);
  //     if (shouldShowModal !== ShouldShowModal.TERMS) {
  //       resetFormRegister();
  //     }

  //     showModal({
  //       value: ShouldShowModal.REGISTER,
  //       title: 'Crie a sua conta',
  //     });
  //   };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormInputNameRegister.state)(cepResponse.state);
      onChangeFormInputRegister(FormInputNameRegister.city)(cepResponse.city);
      onChangeFormInputRegister(FormInputNameRegister.district)(
        cepResponse.neighborhood
      );
      onChangeFormInputRegister(FormInputNameRegister.street)(
        cepResponse.street
      );
    }
  };

  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(title);
    onSetVisible(true);
  };

  const login = async (email: string, password: string): Promise<void> => {
    const payload = Buffer.from(`${email}:${password}`, 'utf8').toString(
      'base64'
    );

    const { data } = await api.post<Auth>(
      '/auth',
      {
        grant_type: 'client_credentials',
      },
      {
        headers: {
          Authorization: `Basic ${payload}`,
        },
      }
    );

    setItem(String(REACT_APP_AUTH), data.token);
    setItem(String(REACT_APP_USER), data.user);
  };

  //   const handleOnCheckCPF = async (): Promise<void> => {
  //     try {
  //       dispatch(setLoading(true));
  //       if (formDataLogin[FormInputNameLogin.document].length === 14) {
  //         const { data } = await api.get<CheckClientDocument>(
  //           `/auth/old/${formDataLogin[FormInputNameLogin.document]}`
  //         );
  //         if (data.changePassword) {
  //           setEmailRecoverPassword(data.client.email);
  //           showModal({
  //             value: ShouldShowModal.CONFIRM_EMAIL_FORGOT_PASSWORD,
  //             title: '',
  //           });
  //         } else if (data.checkConfirmationCode) {
  //           //alert(`Informar o código que está no e-mail: ${response.data.email}`);

  //           setNeedCheckEmailCode(true);
  //           setEmailConfirmation(data.client.email);
  //           showModal({
  //             value: ShouldShowModal.CONFIRM_REGISTER,
  //             title: '',
  //           });
  //         } else {
  //           setEnableLoginPassword(true);
  //           setTimeout(() => {
  //             document.getElementById('loginPWD')?.focus();
  //           }, 200);
  //         }
  //       }
  //     } finally {
  //       dispatch(setLoading(false));
  //     }
  //   };

  const handleOnSubmit = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      if (isFormValidLogin()) {
        dispatch(setLoading(true));
        await login(
          formDataLogin[FormInputNameLogin.email],
          formDataLogin[FormInputNameLogin.password]
        );
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.code && err.code === 'ERR_BAD_REQUEST') {
        setErrorsLogin({
          email: ['E-mail ou Senha inválida'],
          password: ['E-mail ou Senha inválida'],
        });
      } else {
        toast.warn('Falha ao realizar login, tentar novamente mais tarde');
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const handleOnSubmitForgotPassword = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      if (isFormValidForgotPassword()) {
        dispatch(setLoading(true));

        setDocumentClient(
          formDataForgotPassword[FormInputNameRegister.document]
        );

        const payload: RecoverPassword = {
          login: formDataForgotPassword[FormInputNameRegister.document],
        };

        const response = await api.post<RecoverEmail>('/client/email', payload);
        console.log(response);
        setEmailRecoverPassword(response.data.email);
        // showModal({
        //   value: ShouldShowModal.CHECk_EMAIL,
        //   title: '',
        // });

        resetFormForgotPassword();
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnConfirmEmail = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const payload: RecoverPassword = {
        login: documentClient,
      };

      const { data } = await api.post<RecoverEmail>(
        '/auth/recover-password',
        payload
      );

      setEmailRecoverPassword(data.email);
      //   showModal({
      //     value: ShouldShowModal.CONFIRM_EMAIL_FORGOT_PASSWORD,
      //     title: '',
      //   });
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  //   const handleOnChangePersonData = (): void => {
  //     showModal({
  //       value: ShouldShowModal.CHANGE_CLIENT_DATA,
  //       title: '',
  //     });
  //   };

  const handleOnTogglePasswordToText = (): void =>
    setShouldShowPasswordToText(!shouldShowPasswordToText);

  const handleOnTogglePasswordToText2 = (): void =>
    setShouldShowPasswordToText2(!shouldShowPasswordToText2);

  const handleGoToRegisterAppleId = (): void => {
    // TODO: implement logic
  };

  const handleChangePassword = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (isFormValidChangePassword()) {
        dispatch(setLoading(true));
        if (
          formDataChangePassword[FormInputNameRegister.password] !==
          formDataChangePassword[FormInputNameRegister.confirmPassword]
        ) {
          setErrorsChangePassword({
            password: ['Senhas divergentes!'],
            confirmPassword: ['Senhas divergentes!'],
          });
          return;
        }

        const payload: ChangePassword = {
          token,
          password: formDataChangePassword[FormInputNameRegister.password],
          confirmPassword:
            formDataChangePassword[FormInputNameRegister.confirmPassword],
        };

        const response = await api.post<RecoverPassword>(
          '/auth/change-password',
          payload
        );

        await login(
          response.data.login,
          formDataChangePassword[FormInputNameRegister.confirmPassword]
        );
        dispatch(setLoading(false));
        resetFormChangePassword();
        onToggle();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitResendEmail = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      dispatch(setLoading(true));

      if (documentClient !== '') {
        const payload: RecoverPassword = {
          login: documentClient,
        };

        const response = await api.post<RecoverEmail>(
          '/auth/recover-password',
          payload
        );
        setEmailRecoverPassword(response.data.email);
        toast.success('Mensagem de recuperação de senha enviada com sucesso!');
      } else {
        toast.error(
          'Não foi possível enviar a mensagem de recuperação de senha enviada! Informe o dados novamente.'
        );
        showModal({
          value: ShouldShowModal.FORGOT_PASSWORD,
          title: 'Esqueceu a sua senha?',
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmitLogout = () => {
    removeItem(String(REACT_APP_AUTH));
    removeItem(String(REACT_APP_USER));
    history.replace('/');
    onSetVisible(false);
  };

  const onCloseModal = () => {
    onSetVisible(false);
  };

  //   useEffect(() => {
  //     if (token && token.trim().length > 0) {
  //       handleGoToCallChangePassword();
  //     }
  //     const signed = getBoolean(String(REACT_APP_AUTH));
  //     if (signed) {
  //       getClientNotifications();
  //     }
  //     // eslint-disable-next-line
  //   }, [token]);

  //   const onSubmitLogoutsair = () => {
  //     try {
  //       localStorage.clear(); // Remove todos os itens do localStorage
  //       console.log('localStorage limpo com sucesso');
  //     } catch (error) {
  //       console.error('Erro ao limpar localStorage:', error);
  //     }
  //   };

  //   useEffect(() => {
  //     onSubmitLogoutsair();
  //   }, []);

  return (
    <AuthWrapperContainer
      state={loading}
      homepage={isHomepage}
      notifications={notifications}
      onGoTo={handleOnGoTo}
      handleOnSignIn={handleOnSignIn}
      //   handleOnSignUp={handleOnSignUp}
      handleOnLogout={handleOnLogout}
      onSubmitLogout={onSubmitLogout}
      onCloseModal={onCloseModal}
      visibleModal={visible}
      onToggleModal={handleOnToggle}
      titleModal={title}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={showModal}
      shouldShowPasswordToText={shouldShowPasswordToText}
      shouldShowPasswordToText2={shouldShowPasswordToText2}
      onTogglePasswordToText={handleOnTogglePasswordToText}
      onTogglePasswordToText2={handleOnTogglePasswordToText2}
      formDataLogin={formDataLogin}
      formErrorsLogin={formErrorsLogin}
      onChangeFormInputLogin={onChangeFormInputLogin}
      enableLoginPassword={enableLoginPassword}
      //   onCheckCPF={handleOnCheckCPF}
      onSubmitLogin={handleOnSubmit}
      // onGoToLoginGoogle={handleGoToLoginGoogle}
      // onSubmitGoogle={handleOnSubmitGoogle}
      // onGoogleError={handleOnGoogleError}
      // onGoToLoginFacebook={handleGoToLoginFacebook}
      // onSubmitFacebook={handleOnSubmitFacebook}
      // onGoToLoginAppleId={handleGoToLoginAppleId}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeFormInputRegister={onChangeFormInputRegister}
      onChangeCEP={handleOnChangeCEP}
      onGoToRegisterAppleId={handleGoToRegisterAppleId}
      formDataForgotPassword={formDataForgotPassword}
      formErrorsForgotPassword={formErrorsForgotPassword}
      onChangeFormInputForgotPassword={onChangeFormInputForgotPassword}
      onSubmitForgotPassword={handleOnSubmitForgotPassword}
      document={documentClient}
      formDataChangeClientData={formDataChangeClientData}
      formErrorsChangeClientData={formErrorsChangeClientData}
      onChangeFormInputChangeClientData={onChangeFormInputChangeClientData}
      formDataChangePassword={formDataChangePassword}
      formErrorsChangePassword={formErrorsChangePassword}
      onChangeFormInputChangePassword={onChangeFormInputChangePassword}
      onSubmitChangePassword={handleChangePassword}
      formDataRegisterConfirm={formDataRegisterConfirm}
      formErrorsRegisterConfirm={formErrorsRegisterConfirm}
      onChangeFormInputRegisterConfirm={onChangeFormInputRegisterConfirm}
      emailConfirmation={emailConfirmation}
      email={emailRecoverPassword}
      name={nameRecoverPassword}
      onSubmitResendEmail={handleOnSubmitResendEmail}
      onConfirmEmail={handleOnConfirmEmail}
      //   onChangePersonData={handleOnChangePersonData}
    >
      {children}
    </AuthWrapperContainer>
  );
};
