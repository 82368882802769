import cep from 'cep-promise';
import React, { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import {
  FormInputEvent,
  ShouldShowModalProps,
} from 'src/features/core/auth/types';
import { updateMask as updateMaskCEP } from 'src/helpers/masks/cep';
import {
  unmask,
  updateMask as updateMaskDate,
} from 'src/helpers/masks/generalDate';
import validators from 'src/helpers/validators';
import { useDialog } from 'src/hooks/useDialog';
import useForm from 'src/hooks/useForm';
import { Address } from 'src/model/Address';
import { Events } from 'src/model/Event';
import { EventBasic } from 'src/model/UserBasic';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { CreateEventUI, NameFiles } from './ui';

export const CreateEventScreen: React.FC = (): JSX.Element => {
  const { loading } = useSelector(useLoading);
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { visible, onSetVisible } = useDialog();
  const dispatch = useDispatch();

  const {
    formData: formDataEvent,
    formErrors: formErrorEvent,
    setErrors: setErrorEvent,
    onChangeFormInput: onChangeFormInputEvent,
    isFormValid: isFormValidEvent,
    resetForm: resetFormEvent,
  } = useForm({
    initialData: {
      name: '',
      description: '',
      imageBase64: '',
      imageDetail: '',
      startDate: '',
      endDate: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      addressnumber: '',
      country: '',
      payment: '',
    },
    validators: {
      name: [validators.required],
      description: [validators.required],
      imageBase64: [validators.required],
      imageDetail: [validators.required],
      startDate: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      endDate: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      addressnumber: [validators.required],
      country: [validators.required],
      payment: [validators.required],
    },
    formatters: {
      startDate: updateMaskDate,
      endDate: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      console.log('Arquivo selecionado:', file);
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputEvent(inputName)('');
            onChangeFormInputEvent(inputName)(base64);
          }
        };
      } else {
        setErrorEvent({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputEvent(FormInputEvent.state)(cepResponse.state);
      onChangeFormInputEvent(FormInputEvent.city)(cepResponse.city);
      onChangeFormInputEvent(FormInputEvent.district)(cepResponse.neighborhood);
      onChangeFormInputEvent(FormInputEvent.street)(cepResponse.street);
    }
  };

  const handleOnSubmitEvent = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidEvent()) {
        dispatch(setLoading(true));
        const startDateString = unmask(formDataEvent[FormInputEvent.startDate]);
        const endDateString = unmask(formDataEvent[FormInputEvent.endDate]);

        if (!startDateString) {
          const fielName = FormInputEvent.startDate.toString();
          setErrorEvent({ [fielName]: ['Data de nascimento Inválida'] });
          return;
        }

        if (!endDateString) {
          const fielName = FormInputEvent.endDate.toString();
          setErrorEvent({ [fielName]: ['Data de nascimento Inválida'] });
          return;
        }
        const address = {
          zipcode: formDataEvent[FormInputEvent.zipCode],
          state: formDataEvent[FormInputEvent.state],
          city: formDataEvent[FormInputEvent.city],
          district: formDataEvent[FormInputEvent.district],
          street: formDataEvent[FormInputEvent.street],
          complement: formDataEvent[FormInputEvent.complement],
          number: formDataEvent[FormInputEvent.addressnumber],
          country: formDataEvent[FormInputEvent.country],
        } as Address;

        const payload: EventBasic = {
          name: formDataEvent[FormInputEvent.name],
          description: formDataEvent[FormInputEvent.description],
          startDate: startDateString,
          endDate: endDateString,
          address: address,
          image: formDataEvent[FormInputEvent.imageBase64],
          imageDetail: formDataEvent[FormInputEvent.imageDetail],
          payment: formDataEvent[FormInputEvent.payment],
        };
        console.log(payload);
        const response = await api.post<Events>('/event', payload);

        if (response.status === 201) {
          resetFormEvent();
          toast.success('Evento Cadastrado  com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorEvent({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    onSetVisible(true);
  };

  return (
    <AuthWrapper isHomepage={false}>
      <CreateEventUI
        state={loading}
        formData={formDataEvent}
        formErrors={formErrorEvent}
        formNameFiles={formNameFiles}
        onChangeCEP={handleOnChangeCEP}
        onChangeFormInput={onChangeFormInputEvent}
        onSubmitRegister={handleOnSubmitEvent}
        onChangeFormFileInput={handleOnChangeFileInput}
        onShouldShowModal={showModal}
      />
      ;
    </AuthWrapper>
  );
};
