import React, { FormEvent } from 'react';
// import { Header, Footer, Modal } from 'src/components';
import { FormData, FormErrors, OnChangeFormInput } from 'src/hooks';
import { ShouldShowModal, ShouldShowModalProps } from '../../../types';
import { Modal } from '../../../../../../components/Modal';
import { Header } from '../../../../../../components/Header';
import { Footer } from '../../../../../../components/Footer/Footer';
import ClientNotification from '../../../../../../model/ClientNotification';
import { LoginContent } from '../../../components';
import { LogoutContent } from '../../../components/LogoutContent';

interface StateProps {
  state: boolean;
  homepage: boolean;
  notifications: ClientNotification[];
  visibleModal: boolean;
  titleModal: string | React.ReactNode;
  shouldShowModal: ShouldShowModal;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  formDataLogin: FormData;
  formErrorsLogin: FormErrors;
  enableLoginPassword: boolean;
  onChangeFormInputLogin: OnChangeFormInput;
  onChangeCEP: (value: string) => void;
  document: string;
  formDataChangeClientData: FormData;
  formErrorsChangeClientData: FormErrors;
  onChangeFormInputChangeClientData: OnChangeFormInput;
  formDataRegister: FormData;
  formErrorsRegister: FormErrors;
  onChangeFormInputRegister: OnChangeFormInput;
  formDataForgotPassword: FormData;
  formErrorsForgotPassword: FormErrors;
  onChangeFormInputForgotPassword: OnChangeFormInput;
  formDataRegisterConfirm: FormData;
  formErrorsRegisterConfirm: FormErrors;
  onChangeFormInputRegisterConfirm: OnChangeFormInput;
  formDataChangePassword: FormData;
  formErrorsChangePassword: FormErrors;
  onChangeFormInputChangePassword: OnChangeFormInput;
  emailConfirmation: string;
  email: string;
  name: string;
  children: React.ReactNode;
}

interface DispathProps {
  onGoTo: (href: string) => void;
  handleOnSignIn: () => void;
  //   handleOnSignUp: () => void;
  handleOnLogout: () => void;
  onSubmitLogout: () => void;
  onCloseModal: () => void;
  onToggleModal: () => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  //   onCheckCPF: () => void;
  onSubmitLogin: (e: FormEvent) => void;
  // onGoToLoginGoogle: (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => void;
  // onSubmitGoogle: (
  //   response: GoogleLoginResponse | GoogleLoginResponseOffline
  // ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // onGoogleError: (error: any) => void;
  // onGoToLoginFacebook: (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => void;
  // onSubmitFacebook: (
  //   userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  // ) => void;
  // onGoToLoginAppleId: () => void;
  // onSubmitRegister: (e: FormEvent) => void;
  onGoToRegisterAppleId: () => void;
  onSubmitForgotPassword: (e: FormEvent) => void;
  onConfirmEmail: () => void;
  //   onChangePersonData: () => void;
  onSubmitChangePassword: (e: FormEvent) => void;
  onSubmitResendEmail: (e: FormEvent) => void;
}

type AuthWrapperContainerProps = StateProps & DispathProps;

export const AuthWrapperContainer = ({
  state,
  homepage,
  onGoTo,
  handleOnSignIn,
  //   handleOnSignUp,
  handleOnLogout,
  visibleModal,
  onToggleModal,
  onCloseModal,
  onSubmitLogout,
  titleModal,
  onShouldShowModal,
  shouldShowModal,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  formDataLogin,
  formErrorsLogin,
  onChangeFormInputLogin,
  //   enableLoginPassword,
  //   onCheckCPF,
  onSubmitLogin,
  onTogglePasswordToText,
  children,
}: AuthWrapperContainerProps) => {
  return (
    <>
      <div className="wrapper w-full">
        <Header
          homepage={homepage}
          // notifications={notifications}
          goTo={onGoTo}
          handleOnSignIn={handleOnSignIn}
          // handleOnSignUp={handleOnSignUp}
          handleOnLogout={handleOnLogout}
        />
        <main className="overflow-hidden">{children}</main>
        <Footer
          handleOnSignIn={handleOnSignIn}
          //   handleOnSignUp={handleOnSignUp}
          goTo={onGoTo}
        />
      </div>
      <Modal
        visible={visibleModal}
        title={titleModal}
        onSetVisible={onToggleModal}
        actions={[]}
      >
        {shouldShowModal === ShouldShowModal.LOGIN && (
          <LoginContent
            state={state}
            shouldShowPasswordToText={shouldShowPasswordToText}
            shouldShowPasswordToText2={shouldShowPasswordToText2}
            formData={formDataLogin}
            formErrors={formErrorsLogin}
            onChangeFormInput={onChangeFormInputLogin}
            // enableLoginPassword={enableLoginPassword}
            // onCheckCPF={onCheckCPF}
            onSubmit={onSubmitLogin}
            onTogglePasswordToText={onTogglePasswordToText}
            onShouldShowModal={onShouldShowModal}
          />
        )}
        {shouldShowModal === ShouldShowModal.LOGOUT && (
          <LogoutContent
            onCancelConfirm={onCloseModal}
            onOkConfirm={onSubmitLogout}
          />
        )}
      </Modal>
    </>
  );
};
