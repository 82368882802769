import React, { FormEvent } from 'react';
import { CalendarGrey } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { countryOptions } from 'src/components/Country';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { OptionProps, Select } from 'src/components/Select';
import {
  FormInputEvent,
  ShouldShowModalProps,
} from 'src/features/core/auth/types';
import { classNames } from 'src/helpers/common';
import { UseFormReturn } from 'src/hooks';

export interface NameFiles {
  [key: string]: string;
}

export interface CreateEvent
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  formNameFiles?: NameFiles;
  onChangeCEP: (value: string) => void;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}

export const CreateEventUI: React.FC<CreateEvent> = ({
  state,
  formData,
  formErrors,
  formNameFiles,
  onChangeCEP,
  onChangeFormInput,
  onSubmitRegister,
  onChangeFormFileInput,
  //   onShouldShowModal,
}): JSX.Element => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const paymentOptions: OptionProps[] = [
    { value: 'UNIC', label: 'Unico' },
    { value: 'FOR_MESTRE', label: 'Por mestre' },
  ];

  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <form onSubmit={onSubmitRegister}>
        <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
          <div className="ml-5">
            <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
              Cadastro de Evento
            </h1>
          </div>
          <div className="p-3 max-w-4xl mx-auto">
            <div>
              <InputText
                name="name"
                label="Nome do Evento"
                placeholder="Digite aqui..."
                maxLength={100}
                value={formData[FormInputEvent.name]}
                onChange={(e) =>
                  onChangeFormInput(FormInputEvent.name)(e.target.value)
                }
                error={formErrors.name && formErrors.name[0]}
              />
              <InputText
                name="description"
                label="Descrição"
                placeholder="Digite aqui..."
                maxLength={100}
                value={formData[FormInputEvent.description]}
                onChange={(e) =>
                  onChangeFormInput(FormInputEvent.description)(e.target.value)
                }
                error={formErrors.description && formErrors.description[0]}
              />
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="date"
                  label="Data de Inicio"
                  placeholder="DD/MM/AAAA"
                  className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormInputEvent.startDate]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.startDate)(e.target.value)
                  }
                  error={formErrors.startDate && formErrors.startDate[0]}
                  renderForward={
                    <button
                      className={classNames(
                        formErrors.startDate && formErrors.startDate[0]
                          ? 'right-0 bottom-7'
                          : 'right-0 bottom-3',
                        'absolute cursor-pointer mr-4'
                      )}
                      type="button"
                    >
                      <div className="h-8 flex flex-col justify-center items-center">
                        <img src={CalendarGrey} style={{ cursor: 'none' }} />
                      </div>
                    </button>
                  }
                />
                <InputText
                  name="date"
                  label="Data de Termino"
                  placeholder="DD/MM/AAAA"
                  className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormInputEvent.endDate]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.endDate)(e.target.value)
                  }
                  error={formErrors.endDate && formErrors.endDate[0]}
                  renderForward={
                    <button
                      className={classNames(
                        formErrors.endDate && formErrors.endDate[0]
                          ? 'right-0 bottom-7'
                          : 'right-0 bottom-3',
                        'absolute cursor-pointer mr-4'
                      )}
                      type="button"
                    >
                      <div className="h-8 flex flex-col justify-center items-center">
                        <img src={CalendarGrey} style={{ cursor: 'none' }} />
                      </div>
                    </button>
                  }
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputFile
                  refInput={imageBase64Ref}
                  name="imageBase64"
                  label="Imagem Principal"
                  fileName={formNameFiles?.imageBase64}
                  onChange={(e) =>
                    onChangeFormFileInput(FormInputEvent.imageBase64)(
                      (e.target as HTMLInputElement)?.files?.[0]
                    )
                  }
                  error={formErrors.imageBase64 && formErrors.imageBase64[0]}
                />
                <InputFile
                  refInput={imageBase64Ref}
                  name="imageDetail"
                  label="Imagem Detalhes"
                  fileName={formNameFiles?.imageDetail}
                  onChange={(e) =>
                    onChangeFormFileInput(FormInputEvent.imageDetail)(
                      (e.target as HTMLInputElement)?.files?.[0]
                    )
                  }
                  error={formErrors.imageDetail && formErrors.imageDetail[0]}
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <Select
                  name="state"
                  label="Forma de pagamento"
                  placeholder="Selecione..."
                  value={formData[FormInputEvent.payment]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.payment)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.payment && formErrors.payment[0]}
                  options={paymentOptions}
                ></Select>
              </div>
            </div>
            <div>
              <h1 className="mt-10 mb-2 text-gray-700 text-lg font-dmsans font-bold">
                Endereço:
              </h1>

              <div className="mb-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="zipCode"
                  label="CEP"
                  placeholder="00000-000"
                  className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormInputEvent.zipCode]}
                  maxLength={9}
                  onChange={(e) => {
                    onChangeFormInput(FormInputEvent.zipCode)(e.target.value);
                    onChangeCEP(e.target.value);
                  }}
                  error={formErrors.zipCode && formErrors.zipCode[0]}
                />
                <InputText
                  name="street"
                  label="Rua/Avenida/Conjunto"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputEvent.street]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.street)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.street && formErrors.street[0]}
                />
              </div>
              <div className="mb-5 grid grid-cols-1 md:grid-cols-3 gap-4">
                <InputText
                  name="addressNumber"
                  label="Número"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputEvent.addressnumber]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.addressnumber)(
                      e?.target.value as string
                    )
                  }
                  error={
                    formErrors.addressnumber && formErrors.addressnumber[0]
                  }
                />
                <InputText
                  name="complement"
                  label="Complemento (opcional)"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputEvent.complement]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.complement)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.complement && formErrors.complement[0]}
                />
                <InputText
                  name="district"
                  label="Bairro:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputEvent.district]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.district)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.district && formErrors.district[0]}
                />
                <InputText
                  name="city"
                  label="Cidade:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputEvent.city]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.city)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.city && formErrors.city[0]}
                />
                <Select
                  name="state"
                  label="Estado"
                  placeholder="Selecione..."
                  value={formData[FormInputEvent.state]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.state)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.state && formErrors.state[0]}
                  options={stateOptions}
                ></Select>
                <Select
                  name="country"
                  label="Pais"
                  placeholder="Selecione..."
                  value={formData[FormInputEvent.country]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputEvent.country)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.country && formErrors.country[0]}
                  options={countryOptions}
                ></Select>
              </div>
            </div>
          </div>
          <Button
            type="submit"
            title="Criar a minha conta"
            buttonStyle="primary"
            size="xlg"
            className="w-full"
            disabled={
              formData[FormInputEvent.name] === '' ||
              formData[FormInputEvent.description] === '' ||
              formData[FormInputEvent.imageBase64] === '' ||
              formData[FormInputEvent.startDate] === '' ||
              formData[FormInputEvent.endDate] === '' ||
              formData[FormInputEvent.zipCode] === '' ||
              formData[FormInputEvent.state] === '' ||
              formData[FormInputEvent.city] === '' ||
              formData[FormInputEvent.district] === '' ||
              formData[FormInputEvent.street] === '' ||
              formData[FormInputEvent.addressnumber] === '' ||
              formData[FormInputEvent.country] === '' ||
              state
            }
          />
        </div>
      </form>
    </section>
  );
};
