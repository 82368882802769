import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { OrderResponse } from 'src/model/OrderResponse';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { TicketListUI } from './ui';

export const TicketListScreen: React.FC = (): JSX.Element => {
  const [orders, setOrders] = useState<OrderResponse[]>([] as OrderResponse[]);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const getOrders = async (): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<OrderResponse[]>('/order/list-inscritions');
    setOrders(response.data);
    console.log(response.data);
    dispatch(setLoading(false));
  };

  const handleAprovedOrder = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<OrderResponse[]>(
      `/order/order-approval/${id}/APPROVED`
    );
    console.log(response);
    if (response.status === 200) {
      toast.success('Dados salvos com sucesso!');
      getOrders();
    } else if (response.status === 400) {
      const errorMessage =
        typeof response.data === 'string'
          ? response.data
          : JSON.stringify(response.data);
      toast.error(errorMessage);
    }
    dispatch(setLoading(false));
  };

  const handleInativeOrder = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    await api.get<OrderResponse[]>(`/order/order-approval/${id}/DISAPPROVED`);
    getOrders();
    dispatch(setLoading(false));
  };

  const handleCancelOrder = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    await api.get<OrderResponse[]>(`/order/order-approval/${id}/EXPIRED`);
    getOrders();
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      <TicketListUI
        orders={orders}
        state={loading}
        onAprovedOrder={handleAprovedOrder}
        onInativeOrder={handleInativeOrder}
        onCancelOrder={handleCancelOrder}
      />
    </AuthWrapper>
  );
};
