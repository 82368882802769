import React from 'react';

interface TicketData {
  id: string;
  name: string | JSX.Element;
  thumbnail?: string;
  startDate?: string;
  endData?: string;
  status?: JSX.Element;
  statusPayment?: JSX.Element;
  option?: JSX.Element;
}

interface TicketCardProps {
  ticketData: TicketData;
  // onShowPrintTicket: (item: Order) => void;
  // onPrint: (item: OrderItem) => void;
}

const TicketCard: React.FC<TicketCardProps> = ({
  ticketData,
}: // onShowPrintTicket,
// onPrint,
TicketCardProps) => {
  return (
    <div className="w-[48] mb-[30px]  border-2 rounded-md p-2">
      <img
        src={'https://www.ngolocapoeira.org/uploads/' + ticketData.thumbnail}
        alt={typeof ticketData.name === 'string' ? ticketData.name : ''}
        className="m-[1] w-[20] h-auto"
      />
      <div className="texte-center">
        <p className="flex justify-center text[20] text-balance font-bold">
          {typeof ticketData.name === 'string' ? (
            ticketData.name
          ) : (
            <div className="text-base">Data: {ticketData.name}</div>
          )}
        </p>
        {ticketData.status}
        {ticketData.startDate && (
          <p className="mb-3">
            <span className="font-bold">Data:</span> {ticketData.startDate} à{' '}
            {ticketData.endData}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center">{ticketData.option}</div>
    </div>
  );
};

export default TicketCard;
