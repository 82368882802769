import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { getBoolean } from 'src/helpers/common/localStorage';
import { Events } from 'src/model/Event';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { EventUI } from './ui';

interface Params {
  id: string;
}

export const EventScreen = () => {
  const { id } = useParams<Params>();
  const [element, setElement] = useState<HTMLElement | null>();
  const [event, setEvent] = useState<Events>();
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );

  const handleEvent = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      console.log(`Fetching event with ID: ${id}`);
      const { data } = await api.get<Events>(`/event/${id}`);
      console.log('Event data:', data);
      setEvent(data);
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleInscription = (id: string): void => {
    console.log(id);
    history.replace(`/inscrição/${id}`);
  };

  //   const checkLogin = (): void => {
  //     if (element && !signed) {
  //       element.click();
  //     }
  //   };

  const checkPageLoaded = (): void => {
    const interval = setInterval(() => {
      const e = document.getElementById('btnDoLogin');
      if (e && interval) {
        setElement(e);
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    checkPageLoaded();
    // checkLogin();
  }, [signed]);

  useEffect(() => {
    if (element && !signed) {
      element.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    handleEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      {event ? (
        <EventUI event={event} onRegister={handleInscription} />
      ) : (
        <p>Carregando evento...</p>
      )}
    </AuthWrapper>
  );
};
